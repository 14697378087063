import React, { useEffect, useState } from "react";
import "./Plans.css";
import { GiDeadEye } from "react-icons/gi";
import { RxLapTimer } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import UpgradeCard from "../../Components/UpgradeCard/UpgradeCard";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import Timer from "../../Components/Timer";
import { Data, toastFailed, toastSuccess } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import { ethers } from "ethers";
import GetChainId from "../../Common/GetChainId";
import getAddress from "../../Common/GetAddress";
import GetUSDTBalance from "../../Common/GetUsdtBalance";
import ConnectButton from "../../Components/ConnectButton";
import { useSelector } from "react-redux";
import GetUserData from "../../Common/GetUserData";

const Plans = () => {
  const { BigInt } = window;
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [sponsorId, setSponsorId] = useState();
  const [planData, setplanData] = useState([]);
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [checkSponsorExist, setCheckSponsorExist] = useState([]);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const acc = useSelector((state) => state.account.value);
  useEffect(() => {
    getBalance();
    FetchData();
    // checkData();
    // FetchPackages();
    // FetchPlanData();
  }, [acc]);

  async function FetchData() {
    let response = localStorage.getItem("dashboardData");
    let pdata = JSON.parse(response); // Parse the string to an object
    let sponsorAddress = pdata?.profile?.sponsor_wallet_address;
    setSponsorId(sponsorAddress);
    getUserData(sponsorAddress);
    // console.log("DashboardData", sponsorAddress);
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSponsorId(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (value.length > 0) {
          getUserData(value);
        } else {
          // setCheckSponsorExist([])
        }
      }, 500)
    );
  };

  async function getBalance() {
    const tempBalance = await GetUSDTBalance(acc);
    setUsdtBalance(tempBalance);
  }

  async function getUserData(address) {
    try {
      setSponsorLoading(true);
      const tempUserData = await GetUserData(address);
      console.log(tempUserData);
      if (tempUserData?.isExist == true) {
        setCheckSponsorExist(true);
      } else {
        setCheckSponsorExist(false);
      }
      setSponsorLoading(false);
    } catch (error) {
      setSponsorLoading(false);
    }
  }
  async function checkValidation() {
    if (sponsorId.length > 0 && amount > 0) {
      if (checkSponsorExist === true) {
        if (amount > usdtBalance) {
          toastFailed("Insufficient Funds");
        } else {
          increaseAllowance();
        }
      } else {
        toastFailed("Sponsor not exist");
        return false;
      }
    } else {
      toastFailed("Invalid Data");
      return false;
    }
  }
  async function increaseAllowance() {
    setLoading(true);
    try {
      const { ethereum } = window;
      if (ethereum) {
        // Use BigNumber from ethers to handle large numbers safely
        const newAmount = ethers.utils.parseUnits(amount.toString(), 18);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();

        const busdInstance = new ethers.Contract(
          ContractDetails.BUSD,
          ContractDetails.BUSD_ABI,
          signer
        );

        let inc = await busdInstance.increaseAllowance(
          ContractDetails.contract,
          newAmount,
          { value: ethers.utils.parseEther("0") }
        );
        await inc.wait();
        Stake(newAmount);
        Data.isDebug && console.log("Tr Hash 1: " + inc.hash);
      }
    } catch (error) {
      alert("Transaction Failed.");
      Data.isDebug && console.log("error11", error);
      setLoading(false);
    }
  }

  async function Stake() {
    setLoading(true);
    try {
      const { ethereum } = window;
      if (ethereum) {
        const newAmount = ethers.utils.parseUnits(amount.toString(), 18);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractInstance = new ethers.Contract(
          ContractDetails.contract,
          ContractDetails.contractABI,
          signer
        );
        let inc = await contractInstance.stake(newAmount, sponsorId, {
          value: ethers.utils.parseEther("0"),
        });
        await inc.wait();
        toastSuccess("Staking Successfull");
        setLoading(false);
      }
    } catch (error) {
      toastFailed("Transaction Failed");
      Data.isDebug && console.log("error", error);
      setLoading(false);
    }
  }
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="dashboard">
        <ConnectButton />
        <h1 className="textHeadingWithMargin mt-0 mt-4">Your Plan</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="planDiv">
            <div className="addfundDiv inputPrimary ">
              <h1>Activation</h1>
              <div className="subscriptionWallets">
                <p>USDT Balance</p>
                <h5>$ {parseFloat(usdtBalance).toFixed(2)}</h5>
              </div>
              <label htmlFor="Amount">User ID</label>
              {checkSponsorExist == true ? (
                <p id="sponsorVerified">Sponsor Verified</p>
              ) : (
                <p id="sponsorVerified" style={{ color: "red" }}>
                  Sponsor not exist
                </p>
              )}
              <div className="loginInput_inner">
                <input
                  min={1}
                  required
                  type="text"
                  placeholder="Enter Sposnor ID"
                  value={sponsorId}
                  onChange={(e) => handleInputChange(e)}
                />
                {sponsorLoading ? <i id="sponsorLoading"></i> : null}
              </div>
              <label htmlFor="Amount">Amount ($)</label>
              <input
                type="number"
                className="inputPrimary"
                placeholder="Enter Amount"
                style={{ borderRadius: "25px", padding: "8px 20px" }}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <p className="errorMsg">{amountError}</p>
              <button
                className="btnPrimary mt-3"
                onClick={() => checkValidation()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        <Row className="d-flex gap-4"></Row>
      </section>
    </>
  );
};
export default Plans;
